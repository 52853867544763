
import './App.css';
import Navbar from './components/Navbar'
import vector from "./assets/vectors/newVector.jpg";

function App() {
  return (
    <div className="App">
      <Navbar />

      <div className='inner-container'>

      <img src={vector} className='Vector-Image' alt='vector'></img>


      </div>




    </div>
  );
}

export default App;
