import React from 'react';

import "./Navbar.css"

export default function Navbar() {
    return (
        <div className='Navbar-conatciner'>
            <div className='Logo-container'>
                <strong>My college My choice</strong>
            </div>
        </div>
    )
}
